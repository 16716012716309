import React, { useState, useRef, useContext, useEffect } from "react";
import TopBar from "../../components/topbar";
import Header from "../../components/header";
import { ModalDesconto, ModalItemProposta, ModalListaClientes, ModalCliente, ModalConfirmacao } from "../../components/modals";
import { Row, Col, Button, Container, Form as FormBs } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { GlobalContext } from "../../contexts/global";
import { ErroField, InputFieldPadrao, InputClienteProposta, InputFieldTextArea, SelectFieldPadrao, FileFieldPadrao } from "../../components/fields";
import { MdAdd, MdDelete } from "react-icons/md";
import { TbDiscount } from "react-icons/tb";
import { Hint } from "../../components/hint";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup'
import { FaMagic } from "react-icons/fa";

function TelaEdicaoProposta() {

    const { conexao, loading, setLoading, alerta, setAlerta, usuario, setTextoLoading } = useContext(GlobalContext)

    const Navigate = useNavigate()

    const { idproposta } = useParams()

    const schema = Yup.object().shape({
        titulo: Yup.string()
            .required('Campo Título é Obrigatório'),
        cliente: Yup.string()
            .required('Campo Cliente é Obrigatório'),
        apresentacao: Yup.string()
            .required('Campo Apresentação é Obrigatório'),
        tipo_proposta: Yup.string()
            .required('Campo Tipo de Proposta é Obrigatório')
    })

    const [tipoProp, setTipoProp] = useState('padrao')
    const [anexo, setAnexo] = useState(false)
    const [linkAnexo, setLinkAnexo] = useState('')
    const [status, setStatus] = useState('')

    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState(
        {
            cust_id: 0,
            cust_name: '',
            cust_email: '',
            cust_phone: '',
            cust_docnumber: '',
            cust_obs: '',
        }
    );

    const [valuesProposta, setValuesProposta] = useState({})
    const [valuesItem, setValuesItem] = useState({
        descricao: "",
        quantidade: "",
        unitario: "",
    })
    const [showModalItem, setShowModalItem] = useState(false);
    const [showModalDesconto, setShowModalDesconto] = useState(false);
    const [showModalClientes, setShowModalClientes] = useState(false);
    const [showModalConfirmacao, setShowModalConfirmacao] = useState(false);
    const [modalCliente, setModalCliente] = useState(false);

    const [desconto, setDesconto] = useState(0)
    const [itens, setItens] = useState([])

    const somaQuantidades = itens.reduce((acumulador, objeto) => {
        return acumulador + objeto.quantidade;
    }, 0);

    const somaTotais = itens.reduce((acumulador, objeto) => {
        return acumulador + objeto.total;
    }, 0);

    const removerItem = (item) => {
        const novoArray = [...itens]
        novoArray.splice(item, 1)
        setItens(novoArray)
    }

    const salvarProposta = (values, anexo) => {
        const proposta = {
            status: 'rascunho',
            titulo: values.titulo,
            idcliente: valuesProposta.idcliente,
            cliente: values.cliente,
            apresentacao: values.apresentacao,
            tipo: values.tipo_proposta,
            itens: JSON.stringify(itens),
            total: somaTotais,
            desconto: desconto,
            valorFinal: somaTotais - desconto,
            observacoes: values.observacoes,
            validade: values.validade_proposta == '0' ? 0 : Number(values.validade_proposta),
            negociacao: values.permite_negociacao == 'N' ? false : true,
            anexo: anexo ? anexo : null,
        }

        console.log(JSON.stringify(proposta))
        // alert(JSON.stringify(proposta))
        // alert(JSON.stringify(itens))
        insertProposta(proposta)
    }
    const atualizarProposta = (values, arquivo) => {
        const proposta = {
            proposta: base64_decode(idproposta),
            status: 'rascunho',
            titulo: values.titulo,
            idcliente: valuesProposta.idcliente,
            cliente: values.cliente,
            apresentacao: values.apresentacao,
            tipo: values.tipo_proposta,
            itens: JSON.stringify(itens),
            total: somaTotais,
            desconto: desconto,
            valorFinal: somaTotais - desconto,
            observacoes: values.observacoes,
            validade: values.validade_proposta == '0' ? 0 : Number(values.validade_proposta),
            negociacao: values.permite_negociacao == 'N' ? false : true,
            anexo: arquivo ? arquivo : null
        }

        console.log(JSON.stringify(proposta))
        // alert(JSON.stringify(proposta))
        // alert(JSON.stringify(itens))
        updateProposta(proposta)
    }

    const propRef = useRef()

    async function getClientes() {
        axios.post(conexao.endereco + '/customers/list',
            {
                "user": usuario.user_id ? usuario.user_id : localStorage.getItem('userID'),
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {
                    setCustomers(response.data)
                    setShowModalClientes(true)
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function chatGPT() {

        const titulo = propRef.current.values.titulo

        if (!titulo) {
            toast.error('Informe Primeiro o Título da Proposta')
            return
        }

        if (titulo) {

            setTextoLoading('Aguardando Retorno Inteligência Artificial')
            setLoading(true)


            toast.info('A apresentação será criada com base no Título da Proposta')

            axios.post(process.env.REACT_APP_CHATGPT_URL,
                {
                    "question": `Em Português, crie um texto de apresentação de uma proposta comercial com o título de: ${propRef.current.values.titulo}`,
                }, { headers: { token: process.env.REACT_APP_CHATGPT_TOKEN } })

                .then(function (response) {
                    setLoading(false)
                    if (response.status == 200) {
                        propRef.current.setFieldValue('apresentacao', response.data.resposta)
                    }
                })
                .catch(function (error) {
                    console.error(error)
                    setLoading(false)
                })
        }


    }

    async function deleteProposal() {
        axios.post(conexao.endereco + '/proposals/delete',
            {
                "user": usuario.user_id ? usuario.user_id : localStorage.getItem('userID'),
                "proposal": base64_decode(idproposta)
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {
                    Navigate('/propostas')
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }



    async function getClientes() {
        axios.post(conexao.endereco + '/customers/list',
            {
                "user": usuario.user_id ? usuario.user_id : localStorage.getItem('userID'),
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {
                    setCustomers(response.data)
                    setShowModalClientes(true)
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function insertCliente(values) {
        setModalCliente(false)
        axios.post(conexao.endereco + '/customers/create',
            {
                "user": usuario.user_id ? usuario.user_id : localStorage.getItem('userID'),
                "name": values.name,
                "email": values.email,
                "phone": values.phone,
                "docnumber": values.docnumber,
                "obs": values.obs,
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 201) {
                    propRef.current.setFieldValue('cliente', response.data.name)
                    setValuesProposta({
                        idcliente: response.data.id,
                    })
                } else {
                    setAlerta({
                        show: true,
                        titulo: 'Retorno API',
                        mensagem: response.data.message,
                        textoBotao: 'Ok'
                    })
                }
            })
            .catch(function (error) {
                console.error(error)
                setModalCliente(true)
                setLoading(false)
                setAlerta({
                    show: true,
                    titulo: 'ATENÇÃO!',
                    mensagem: error.response.data.message,
                    textoBotao: 'Ok'
                })
            })
    }

    async function insertProposta(values) {
        axios.post(conexao.endereco + '/proposals/create',
            {
                "user": usuario.user_id ? usuario.user_id : localStorage.getItem('userID'),
                "title": values.titulo,
                "customer": values.idcliente,
                "description": values.apresentacao,
                "type": values.tipo,
                "items": values.itens,
                "payment_conditions": values.observacoes,
                "total": values.total,
                "discount": values.desconto,
                "final_value": values.valorFinal,
                "negociation": values.negociacao,
                "validity": values.validade,
                "attachment": values.anexo ? values.anexo : null,
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 201) {
                    Navigate('/propostas/')
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function updateProposta(values) {
        axios.post(conexao.endereco + '/proposals/edit',
            {
                "proposal": values.proposta,
                "user": usuario.user_id ? usuario.user_id : localStorage.getItem('userID'),
                "title": values.titulo,
                "customer": values.idcliente,
                "description": values.apresentacao,
                "type": values.tipo,
                "items": values.itens,
                "payment_conditions": values.observacoes,
                "total": values.total,
                "discount": values.desconto,
                "final_value": values.valorFinal,
                "negociation": values.negociacao,
                "validity": values.validade,
                "attachment": values.anexo
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {
                    toast.success('Proposta atualizada com sucesso!')
                    getProposta()
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function getProposta() {
        axios.post(conexao.endereco + '/proposals/get',
            {
                "user": usuario.user_id ? usuario.user_id : localStorage.getItem('userID'),
                "proposal": base64_decode(idproposta)
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {
                    propRef.current.setFieldValue('titulo', response.data.prop_title)
                    propRef.current.setFieldValue('cliente', response.data.cust_name)
                    setValuesProposta({
                        idcliente: response.data.cust_id,
                    })
                    propRef.current.setFieldValue('apresentacao', response.data.prop_description)
                    propRef.current.setFieldValue('tipo_proposta', response.data.prop_type)
                    setTipoProp(response.data.prop_type)
                    setItens(JSON.parse(response.data.prop_items))
                    propRef.current.setFieldValue('observacoes', response.data.prop_payment_conditions)
                    propRef.current.setFieldValue('total', response.data.prop_total)
                    propRef.current.setFieldValue('desconto', response.data.prop_total)
                    propRef.current.setFieldValue('totalFinal', response.data.prop_totalFinal)
                    propRef.current.setFieldValue('permite_negociacao', response.data.prop_negociation == false ? 'N' : 'S')
                    propRef.current.setFieldValue('validade_proposta', response.data.prop_validity)
                    setDesconto(response.data.prop_discount ? Number(response.data.prop_discount) : 0)
                    setAnexo(response.data.prop_attachment ? true : false)
                    setLinkAnexo(response.data.nome_arquivo ? response.data.nome_arquivo : null)
                    setStatus(response.data.prop_status)

                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function UploadArquivo(values) {

        setLoading(true)

        const formData = new FormData()
        formData.append('arquivo', values.file)

        axios.post(conexao.endereco + '/arquivos/enviar',
            formData, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                if (idproposta) {
                    atualizarProposta(values, response.data.url)
                } else {
                    salvarProposta(values, response.data.url)
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function RemoverAnexo(fileName) {

        setLoading(true)

        axios.post(conexao.endereco + '/arquivos/remover',
            {
                arquivo: fileName
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                if (response.status == 200) {
                    propRef.current.setFieldValue('file', null)
                    propRef.current.setFieldValue('anexo', null)
                    setAnexo(false)
                    setLinkAnexo(null)
                    atualizarProposta(propRef.current.values, null)
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }


    useEffect(() => {
        if (customer) {
            propRef.current.setFieldValue('cliente', customer.cust_name)
            setValuesProposta({
                idcliente: customer.cust_id,
            })
        }
    }, [customer])


    useEffect(() => {
        if (idproposta) {
            getProposta()
        }
    }, [])

    return (
        <div>
            <TopBar ativo='propostas' />
            <Container style={{ marginBottom: 60 }}>
                <Header titulo={idproposta ? 'Edição de Proposta' : 'Nova Proposta'}>
                    <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" block type="buttom" form="propRef" onClick={() => {
                        Navigate('/propostas/')
                    }}>
                        Voltar
                    </Button>
                    {idproposta &&
                        <Button style={{ backgroundColor: 'red', border: 'none', marginLeft: 15 }} disabled={status == 'aprovada' ? true : false} size="sm" block type="buttom" form="propRef" onClick={() => {
                            setShowModalConfirmacao(true)
                        }}>
                            Excluir Proposta
                        </Button>
                    }
                    {idproposta &&
                        <Button style={{ backgroundColor: '#026eb6', border: 'none', marginLeft: 15 }} size="sm" block type="submit" form="formulario">
                            Atualizar
                        </Button>
                    }
                    {!idproposta &&
                        <Button style={{ backgroundColor: '#026eb6', border: 'none', marginLeft: 15 }} size="sm" block type="submit" form="formulario" >
                            Salvar
                        </Button>
                    }
                </Header>

                <Formik
                    onSubmit={(values) => {
                        if (idproposta) {
                            if (values.file) {
                                UploadArquivo(values)
                            } else {
                                atualizarProposta(values)
                            }
                        } else {
                            if (values.file) {
                                UploadArquivo(values)
                            } else {
                                salvarProposta(values)
                            }

                        }

                    }}
                    innerRef={propRef}
                    validationSchema={schema}

                    initialValues={{
                        titulo: valuesProposta.prop_title,
                        cliente: valuesProposta.cliente,
                        apresentacao: valuesProposta.prop_description,
                        tipo_proposta: valuesProposta.tipo_proposta,
                        itens: valuesProposta.itens,
                    }}
                    render={({ values, errors, touched, isValid, setFieldValue }) => (
                        <>
                            <Form autoComplete="off" style={{ paddingLeft: 25, paddingRight: 25 }} id="formulario" >
                                <Row>
                                    <Col>
                                        <InputFieldPadrao campo="titulo" texto="Título da Proposta" tipo="text" />
                                        <ErroField campo="titulo" />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 40 }}>
                                    <Col>
                                        <InputClienteProposta
                                            campo="cliente"
                                            descricao="Cliente"
                                            exibeRemover={valuesProposta.idcliente > 0 ? true : false}
                                            clickSearch={() => {
                                                getClientes()
                                            }}
                                            clickAdd={() => {
                                                setCustomer({
                                                    cust_id: 0,
                                                    cust_name: '',
                                                    cust_email: '',
                                                    cust_phone: '',
                                                    cust_docnumber: '',
                                                    cust_obs: '',
                                                })
                                                setModalCliente(true)
                                            }}
                                            clickRemove={() => {
                                                propRef.current.setFieldValue('cliente', '')
                                                setValuesProposta({
                                                    idcliente: ''
                                                })
                                            }}
                                        />
                                        <ErroField campo="cliente" />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 15 }}>
                                    <Col lg={12}>

                                        <InputFieldTextArea
                                            campo="apresentacao"
                                            texto="Texto de Apresentação"
                                            tipo="text"
                                            linhas={3}
                                            icone={<span onClick={() => { chatGPT() }} style={{ marginLeft: 10, cursor: 'pointer', color: 'white', backgroundColor: '#026eb6', borderRadius: 5, padding: 5, fontSize: 11 }}><FaMagic style={{ marginRight: 5, marginLeft: 5 }} />Gerar Texto com Inteligência Artificial</span>}
                                        />
                                        <ErroField campo="apresentacao" />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 15 }}>
                                    <Col lg={3}>
                                        <SelectFieldPadrao campo="tipo_proposta" texto="Tipo de Proposta" onChange={(event) => {
                                            setTipoProp(event.target.value)
                                            propRef.current.setFieldValue('tipo_proposta', event.target.value)
                                        }}>
                                            <option value={null}>Selecione</option>
                                            <option value={'padrao'}>Padrão - Todos os Itens</option>
                                            <option value={'individual'}>Individual - Cliente deve escolher o item</option>
                                        </SelectFieldPadrao>
                                        <ErroField campo="tipo_proposta" />
                                    </Col>
                                    <Col lg={2}>
                                        <SelectFieldPadrao campo="permite_negociacao" texto="Permite Negociação" onChange={(event) => {
                                            propRef.current.setFieldValue('permite_negociacao', event.target.value)
                                        }}>
                                            <option value={'S'}>Sim</option>
                                            <option value={'N'}>Não</option>
                                        </SelectFieldPadrao>
                                    </Col>
                                    <Col lg={2}>
                                        <SelectFieldPadrao campo="validade_proposta" texto="Validade da Proposta" onChange={(event) => {
                                            propRef.current.setFieldValue('validade_proposta', event.target.value)
                                        }}>
                                            <option value={'0'}>Sem Validade</option>
                                            <option value={'3'}>3 dias</option>
                                            <option value={'7'}>7 dias</option>
                                            <option value={'15'}>15 dias</option>
                                            <option value={'30'}>30 dias</option>
                                        </SelectFieldPadrao>
                                    </Col>
                                    {anexo &&
                                        <Col lg={5}>

                                            <FormBs>
                                                <FormBs.Group className="mb-3" controlId="formBasicEmail">
                                                    <FormBs.Label>Arquivo Anexo<sup ><a style={{ textDecoration: 'none', marginLeft: 10, color: 'red' }} href="#" onClick={() => { RemoverAnexo(linkAnexo) }} >Remover</a></sup></FormBs.Label>
                                                    <FormBs.Control type="text" disabled value={linkAnexo} />
                                                </FormBs.Group>
                                            </FormBs>
                                        </Col>
                                    }
                                    {!anexo &&
                                        <Col lg={5}>
                                            <FileFieldPadrao campo="anexo" texto="Anexar Arquivo na Proposta" change={(event) => {
                                                setFieldValue("file", event.currentTarget.files[0])
                                            }} />
                                            <ErroField campo="anexo" />
                                        </Col>
                                    }
                                </Row>
                                <Row style={{ marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Col lg={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        Itens da Proposta
                                        <Hint posicao="top" id="add-item-proposta" texto="Adicionar Item na Proposta">
                                            <div style={{ width: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginLeft: 15 }}>
                                                <MdAdd style={{ color: 'white', backgroundColor: '#026eb6', borderRadius: 50, minWidth: 15, minHeight: 15 }} onClick={() => { setShowModalItem(true) }} />
                                            </div>
                                        </Hint>

                                    </Col>
                                </Row>
                                {itens.length > 0 &&
                                    <>
                                        <Row style={{ marginTop: 15 }}>
                                            <Col>
                                                <div style={{ backgroundColor: 'gainsboro', width: '100%', height: 40, borderStartStartRadius: 10, borderStartEndRadius: 10, display: 'flex' }}>
                                                    <Col lg={5} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                                        Descrição do item
                                                    </Col>
                                                    <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                                        Quantidade
                                                    </Col>
                                                    <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                                        Valor Unitário
                                                    </Col>
                                                    <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                                        Valor Total
                                                    </Col>
                                                </div>
                                                {itens.map((item, index) => (

                                                    <div style={{ backgroundColor: 'white', width: '100%', display: 'flex' }}>
                                                        <Col lg={5} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15, textTransform: 'capitalize' }}>
                                                            {item.descricao}
                                                        </Col>
                                                        <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                                            {item.quantidade}
                                                        </Col>
                                                        <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                                            {item.unitario ? item.unitario.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '0,00'}
                                                        </Col>
                                                        <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                                            {item.total ? item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '0,00'}
                                                        </Col>
                                                        <Col lg={1} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                                            <Hint posicao="top" id="delete-item" texto="Remover Item">
                                                                <div onClick={() => { removerItem(index) }} style={{ cursor: 'pointer' }}>
                                                                    <MdDelete color="red" />
                                                                </div>
                                                            </Hint>
                                                        </Col>
                                                    </div>

                                                ))}
                                                <div style={{ backgroundColor: 'gainsboro', width: '100%', height: 40, borderEndStartRadius: 10, borderEndEndRadius: 10, display: 'flex' }}>
                                                    <Col lg={5} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>

                                                    </Col>
                                                    <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                                        {tipoProp === 'padrao' && <strong>{somaQuantidades}</strong>}
                                                    </Col>
                                                    <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>

                                                    </Col>
                                                    <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                                        {tipoProp === 'padrao' && <strong>{somaTotais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>}
                                                    </Col>
                                                </div>
                                            </Col>
                                        </Row>
                                        {tipoProp === 'padrao' &&
                                            <Row style={{ marginTop: 45 }}>
                                                <Col style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column' }}>


                                                    <div style={{ backgroundColor: 'white', width: '100%', display: 'flex', borderRadius: 15 }}>
                                                        <Col lg={6} style={{ display: 'flex', alignItems: 'flex-start', height: 180, justifyContent: 'flex-start', paddingLeft: 15, paddingTop: 10, flexDirection: 'column' }}>
                                                            <span style={{ fontWeight: 500 }}>Condições de Pagamento</span>
                                                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', padding: 10, height: '100vh', width: '200%' }}>

                                                                {/* <textarea style={{ width: '100%', height: '90%', border: '2px solid gainsboro', borderRadius: 5 }} /> */}
                                                                <InputFieldTextArea campo="observacoes" tipo="text" linhas={4} />
                                                            </div>

                                                        </Col>

                                                    </div>



                                                </Col>
                                                <Col style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column' }}>
                                                    <div style={{ backgroundColor: 'white', width: '100%', height: 60, borderStartStartRadius: 10, borderStartEndRadius: 10, display: 'flex' }}>
                                                        <Col lg={6} style={{ display: 'flex', alignItems: 'center', height: 60, justifyContent: 'flex-start', paddingLeft: 15 }}>
                                                            <span style={{ fontWeight: 500 }}>Total dos itens</span>
                                                        </Col>
                                                        <Col lg={6} style={{ display: 'flex', alignItems: 'center', height: 60, justifyContent: 'flex-end', paddingRight: 15 }}>
                                                            <strong>{somaTotais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>
                                                        </Col>
                                                    </div>

                                                    <div style={{ backgroundColor: 'white', width: '100%', display: 'flex' }}>
                                                        <Col lg={6} style={{ display: 'flex', alignItems: 'center', height: 60, justifyContent: 'flex-start', paddingLeft: 15 }}>
                                                            <span style={{ fontWeight: 500 }}>Desconto</span>
                                                            <Hint posicao="top" id="descont-item" texto="Informar Desconto">
                                                                <div onClick={() => { setShowModalDesconto(true) }} style={{ cursor: 'pointer', marginLeft: 10 }}>
                                                                    <TbDiscount color="#026eb6" />
                                                                </div>

                                                            </Hint>


                                                        </Col>
                                                        <Col lg={6} style={{ display: 'flex', alignItems: 'center', height: 60, justifyContent: 'flex-end', paddingRight: 15 }}>
                                                            <strong>{desconto ? desconto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}</strong>
                                                        </Col>
                                                    </div>


                                                    <div style={{ backgroundColor: 'gainsboro', width: '100%', height: 60, borderEndStartRadius: 10, borderEndEndRadius: 10, display: 'flex' }}>

                                                        <Col lg={6} style={{ display: 'flex', alignItems: 'center', height: 60, justifyContent: 'flex-start', paddingLeft: 15 }}>
                                                            <span style={{ fontWeight: 600 }}>Valor Final da Proposta</span>
                                                        </Col>
                                                        <Col lg={6} style={{ display: 'flex', alignItems: 'center', height: 60, justifyContent: 'flex-end', paddingRight: 15 }}>
                                                            <strong>{(somaTotais - desconto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>
                                                        </Col>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                    </>
                                }
                            </Form>

                        </>
                    )}
                />
            </Container>
            <ToastContainer />

            <ModalItemProposta
                show={showModalItem}
                setShow={setShowModalItem}
                onHide={() => setShowModalItem(false)}
                centered
                dialogClassName="modal-50"
                values={valuesItem}
                itens={itens}
                setItens={setItens}
            />
            <ModalDesconto
                show={showModalDesconto}
                setShow={setShowModalDesconto}
                onHide={() => setShowModalDesconto(false)}
                centered
                desconto={desconto}
                setDesconto={setDesconto}
                valor={somaTotais}
            />
            <ModalConfirmacao
                show={showModalConfirmacao}
                setShow={setShowModalConfirmacao}
                onHide={() => setShowModalConfirmacao(false)}
                centered
                titulo="Confirma Exclusão da Proposta?"
                texto="Está Ação é irreversível"
                clickConfirmar={() => {
                    deleteProposal()
                }}
            />
            <ModalListaClientes
                show={showModalClientes}
                setShow={setShowModalClientes}
                onHide={() => setShowModalClientes(false)}
                dialogClassName="modal-75"
                centered
                customers={customers}
                setCustomer={setCustomer}
            />
            <ModalCliente
                show={modalCliente}
                setShow={setModalCliente}
                onHide={() => setModalCliente(false)}
                centered
                type={'insert'}
                insertCliente={insertCliente}
                values={customer}
            />

        </div>
    )
}

export default TelaEdicaoProposta