import React from "react"
import { Col, Row, OverlayTrigger, Tooltip, Alert, InputGroup } from 'react-bootstrap'
import { MdFileDownload, MdDelete, MdFileUpload, MdSearch, MdAdd, MdClose } from 'react-icons/md'
import { Field, ErrorMessage } from "formik"
import { Hint } from "./hint"

export function InputFieldPadrao(props) {
    return (
        <>
            <label htmlFor={props.campo} className="form-label">{props.texto}</label>
            <Field id={props.campo} name={props.campo} type={props.tipo} className="form-control"  onBlur={props.blur} disabled={props.disabled} />
        </>
    )
}

export function InputFieldTextArea(props) {
    return (
        <>
            <label htmlFor={props.campo} className="form-label">{props.texto}</label>{props.icone}
            <Field id={props.campo} name={props.campo} type={props.tipo} as="textarea" rows={props.linhas} className="form-control" onBlur={props.blur} />
        </>
    )
}

export function CheckFieldPadrao(props) {
    return (
        <>
            <label>
                <Field type="checkbox" name={props.campo} className="form-check-input" />
                &nbsp;&nbsp;&nbsp;{props.children}
            </label>
        </>
    )
}

export function SelectFieldPadrao(props) {
    return (
        <>

            <label htmlFor={props.campo} className="form-label">{props.texto}</label>
            <Field id={props.campo} name={props.campo} as="select" className="form-select" onChange={props.onChange}>
                {props.children}
            </Field>


        </>
    )
}

export function FileFieldPadrao(props) {
    return (
        <div>
            <label htmlFor={props.campo} className="form-label">{props.texto}</label>
            <Field id={props.campo} name={props.campo} type="file" className="form-control" accept={props.accept} onChange={props.change} />
        </div>
    )
}

export function ErroField(props) {
    return (
        <ErrorMessage name={props.campo} render={msg => <div style={{ color: 'red', fontSize: 10, paddingTop: 5, paddingLeft: 2 }}>* {msg}</div>} />
    )
}
export function DocumentoField(props) {
    return (
        <Col style={{ backgroundColor: 'white', width: 200, height: 120, borderRadius: 15, padding: 15 }} className="form-control">
            <Row>
                <Col>
                    <strong>{props.nome}</strong>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col lg={6}>
                    <span>{props.status}</span>
                </Col>
                <Col lg={2} style={{ display: 'flex', justifyContent: 'center' }}>
                    <OverlayTrigger trigger="hover" placement="top" overlay={<Tooltip id="tooltip_enviar">Enviar Arquivo</Tooltip>}>
                        <label htmlFor={props.campo} className="form-label">
                            <div style={{ backgroundColor: '#E30613', border: 0, height: 30, width: 32, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                <MdFileUpload color="white" />
                            </div>
                        </label>
                    </OverlayTrigger>
                    <Field id={props.campo} name={props.campo} type={"file"} className="form-control" accept="image/png, image/jpeg, application/pdf" onChange={props.onChange} value={null} style={{ display: 'none' }} />
                </Col>
                {props.status !== 'Pendente' &&
                    <Col lg={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={<Tooltip id="tooltip_baixar">Baixar Arquivo</Tooltip>}>
                            <div style={{ backgroundColor: '#E30613', border: 0, height: 30, width: 32, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={props.clickDownload}>
                                <MdFileDownload color="white" />
                            </div>
                        </OverlayTrigger>
                    </Col>
                }
                {props.status !== 'Pendente' &&
                    <Col lg={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <OverlayTrigger trigger="hover" placement="bottom" overlay={<Tooltip id="tooltip_remover">Remover Arquivo</Tooltip>}>
                            <div style={{ backgroundColor: '#E30613', border: 0, height: 30, width: 32, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={props.clickRemove}>
                                <MdDelete color="white" />
                            </div>
                        </OverlayTrigger>
                    </Col>
                }
            </Row>

        </Col>
    )
}

export function AlertaErro(props) {

    return (
        <Alert key='danger' variant='danger'><strong>{props.nome}</strong> {props.campo}</Alert>
    )
}

export function InputClienteProposta(props) {
    return (
        <InputGroup className="mb-3">
            <InputGroup.Text
                id="inputGroup-sizing-default"
                style={{ minWidth: 100, backgroundColor: '#f5f5f5', display: 'flex', justifyContent: 'space-between' }}
            >
                {props.descricao}

            </InputGroup.Text>
            <Hint posicao="top" id="search-button" texto="Pesquisar Cliente">
                <InputGroup.Text
                    id="inputGroup-sizing-default"
                    onClick={props.clickSearch}
                    style={{ minWidth: 30, backgroundColor: '#f5f5f5', color: 'gray', display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
                >
                    <MdSearch color="gray" style={{ cursor: 'pointer' }} />

                </InputGroup.Text>
            </Hint>
            <Hint posicao="top" id="add-button" texto="Cadastrar Cliente">
                <InputGroup.Text
                    id="inputGroup-sizing-default"
                    onClick={props.clickAdd}
                    style={{ minWidth: 30, backgroundColor: '#f5f5f5', color: 'gray', display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
                >
                    <MdAdd color="gray" />

                </InputGroup.Text>
            </Hint>
            <Field
                id={props.campo}
                name={props.campo}
                type="text"
                className="form-control"
                disabled
            />
            {props.exibeRemover &&
                <Hint posicao="top" id="remove-button" texto="Remover Cliente da Proposta">
                    <InputGroup.Text
                        id="inputGroup-sizing-default"
                        onClick={props.clickRemove}
                        style={{ minWidth: 20, backgroundColor: '#f5f5f5', color: 'gray', display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
                    >
                        <MdClose color="gray" />
                    </InputGroup.Text>
                </Hint>
            }

        </InputGroup>
    )
}