import React, { useState, useEffect, useContext } from "react"
import { useNavigate, } from 'react-router-dom';
import { GlobalContext } from "../../contexts/global";
import './style.css';
import md5 from "js-md5";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field } from 'formik'
import { ErroField } from '../../components/fields'
import * as Yup from 'yup';
import axios from 'axios'
import { AlertaMensagem } from "../../components/alerts";

function TelaLogin() {

    const [textoAcessar, setTextoAcessar] = useState('Acessar')
    const [exibeMsg, setExibeMsg] = useState(false)
    const [tituloMsg, setTituloMsg] = useState(false)
    const [textoMsg, setTextoMsg] = useState(false)

    const { conexao, loading, setLoading, alerta, setAlerta } = useContext(GlobalContext)

    const Navigate = useNavigate()

    useEffect(() => {
        if (loading) {
            setTextoAcessar('Aguarde...')
        } else {
            setTextoAcessar('Acessar')
        }
    }, [loading])

    useEffect(() => {
        localStorage.removeItem('Token')
        localStorage.removeItem('userID')
    }, [])

    function fazerLogin(values, actions) {
        console.log('Dados para Login', values)
        Autenticar(values.usuario, values.senha)
    }

    function Autenticar(usuario, senha) {

        setLoading(true)

        axios.post(conexao.endereco + '/login',
            {
                "email": usuario,
                "password": md5(senha).toUpperCase()
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                if (response.status == 200) {
                    setLoading(false)
                    console.log('Login realizado com sucesso')
                    localStorage.setItem('email', usuario)
                    localStorage.setItem('Token', response.data.token)
                    localStorage.setItem('userID', response.data.userId)
                    Navigate('/dashboard')
                } else {
                    setLoading(false)
                    setAlerta({
                        show: true,
                        titulo: 'Acesso Negado',
                        mensagem: 'Usuário ou Senha Inválidos',
                        textoBotao: 'Ok'
                    })

                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error)
            })

    }

    const SchemaLogin = Yup.object().shape({
        usuario: Yup.string()
            .required('Campo Obrigatório'),
        senha: Yup.string()
            .required('Campo Obrigatório')
            .min(3, 'Senha deve ter ao menos 3 digitos'),
    })

    return (
        <div className="main-login">

            {/* <div className="left-login" /> */}

            <div className="right-login">
                <div className="card-login">
                    <div className="logo-login">
                        <h3 className="texto-logo">Gestão de Propostas Comerciais</h3>
                        <p style={{ color: 'grey', textAlign: 'center' }}>Acesse sua conta</p>
                    </div>
                    <Formik
                        onSubmit={fazerLogin}
                        validationSchema={SchemaLogin}
                        initialValues={{
                            usuario: localStorage.getItem('email'),
                            senha: ''
                        }}
                        render={({ values, errors, touched, isValid }) => (
                            <>
                                <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="textfield">
                                        <label for="usuario">Usuário</label>
                                        <Field name="usuario" type="text" />
                                        <ErroField campo="usuario" />
                                    </div>
                                    <div className="textfield">
                                        <label for="senha">Senha</label>
                                        <Field name="senha" type="password" />
                                        <ErroField campo="senha" />
                                    </div>

                                    <button className="btn-login" type="submit" >{textoAcessar}</button>
                                </Form>

                            </>
                        )}
                    />

                    <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div>
                            <span>Não tem cadastro?</span> <a href="/cadastro" style={{ textDecoration: 'none' }}><strong style={{ marginLeft: 10 }}>Experimente grátis</strong></a>
                        </div>
                        <div style={{ marginTop: 40 }}>
                            <span>E-mail do Suporte: suporte@outbox.inf.br</span>
                        </div>


                    </div>
                </div>
            </div>

            {/* <TelaLogin exibir={exibeMsg} titulo="Retorno API" mensagem={textoMsg} /> */}

            <Modal show={exibeMsg} centered>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    {tituloMsg}
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {textoMsg}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setExibeMsg(false) }}>OK</Button>
                </Modal.Footer>
            </Modal>

            <AlertaMensagem
                show={alerta.show}
                close={() => setAlerta({ ...alerta, show: false })}
                titulo={alerta.titulo}
                mensagem={alerta.mensagem}
                textoBotao={alerta.textoBotao}
            />
        </div>
    )
}

export default TelaLogin