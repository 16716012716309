import React, { useContext, useEffect } from "react"
import { Nav, Navbar, Container } from 'react-bootstrap'
import { useNavigate, } from 'react-router-dom';
import { GlobalContext } from "../contexts/global";
import axios from "axios";
import { ModalLoading } from "./modals";

export default function TopBar(props) {

    const Navigate = useNavigate()

    const { usuario, conexao, setUsuario, loading, setLoading, textoLoading } = useContext(GlobalContext)

    async function ValidaToken(token) {


        axios.post(conexao.endereco + '/token/validacao',
            {
                "token": token
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                if (response.status !== 200) {
                    Navigate('/')
                } else {
                    setUsuario(response.data.user)
                    localStorage.setItem('email', response.data.user.user_email)
                    localStorage.setItem('Token', response.data.dados.tok_token)
                    localStorage.setItem('userID', response.data.user.user_id)
                    if (response.data.user.user_plan == 0 && response.data.user.restante <= 0) {
                        Navigate('/dashboard')
                    }
                }
            })
            .catch(function (error) {
                Navigate('/')
            })
    }




    const itensMenu = [
        {
            nome: 'Dashboard',
            link: '/dashboard',
            slug: 'dashboard'
        },
        {
            nome: 'Clientes',
            link: '/clientes',
            slug: 'clientes'
        },
        {
            nome: 'Propostas',
            link: '/propostas',
            slug: 'propostas'
        },
        // {
        //     nome: 'Financeiro',
        //     link: '/financeiro',
        //     slug: 'financeiro'
        // },
        {
            nome: 'Configurações',
            link: '/configuracoes',
            slug: 'configuracoes'
        },
        {
            nome: 'Sair',
            link: '/',
            slug: 'sair'
        },
    ]

    useEffect(() => {

        if (!props.cliente) {
            const getTokenAtual = async () => {
                const tokenObtido = await localStorage.getItem('Token')
                if (tokenObtido) {
                    ValidaToken(tokenObtido)
                } else {
                    Navigate('/')
                }
            }


            getTokenAtual()
            console.debug('Validando Token')
        }


    }, [])

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="navbar-dark" style={{ backgroundColor: '#026eb6' }}>
                <Container>
                    <Navbar.Brand className="mb-0 h1 titulo-topbar" style={{ cursor: 'pointer' }} onClick={() => { Navigate('/dashboard') }}>Gestão de Propostas Comerciais</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    {!props.cliente &&
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ms-auto">
                                {itensMenu.map(item => (
                                    <Nav.Link key={item.slug} className={props.ativo === `${item.slug}` ? 'active menu-elevado' : ' menu-elevado'} style={{ marginLeft: 15 }} onClick={() => {
                                        Navigate(item.link)
                                    }}>{item.nome}</Nav.Link>
                                ))}
                            </Nav>
                        </Navbar.Collapse>
                    }
                </Container>
            </Navbar>
            <ModalLoading
                centered
                show={loading}
                texto={textoLoading}
            />
        </>
    )

}