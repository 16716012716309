import React, { useEffect, useState, useContext } from "react";
import TopBar from "../../components/topbar";
import { Container, Button, Row, Col, Modal } from "react-bootstrap";
import Header from "../../components/header";
import axios from "axios";
import { GlobalContext } from "../../contexts/global";
import { Hint } from "../../components/hint";
import { MdDelete, MdEdit } from "react-icons/md";
import { ModalCliente, ModalConfirmacao } from "../../components/modals";
import { formatToCPFOrCNPJ, formatToPhone } from "brazilian-values";
import { AlertaMensagem } from "../../components/alerts";

function TelaClientes() {

    const { conexao, loading, setLoading, alerta, setAlerta, usuario } = useContext(GlobalContext)


    const [customers, setCustomers] = useState([]);
    const [edit, setEdit] = useState(false);
    const [customer, setCustomer] = useState(
        {
            cust_id: 0,
            cust_name: '',
            cust_email: '',
            cust_phone: '',
            cust_docnumber: '',
            cust_obs: '',
        }
    );


    const [modalCliente, setModalCliente] = useState(false);
    const [showConfirmacao, setShowConfirmacao] = useState(false);

    const [idSelecionado, setIdSelecionado] = useState(0)

    async function getClientes() {
        axios.post(conexao.endereco + '/customers/list',
            {
                "user": usuario.user_id ? usuario.user_id : localStorage.getItem('userID'),
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {
                    setCustomers(response.data)
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function insertCliente(values) {
        setModalCliente(false)
        axios.post(conexao.endereco + '/customers/create',
            {
                "user": usuario.user_id ? usuario.user_id : localStorage.getItem('userID'),
                "name": values.name,
                "email": values.email,
                "phone": values.phone,
                "docnumber": values.docnumber,
                "obs": values.obs,
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 201) {
                    getClientes()
                } else {
                    setAlerta({
                        show: true,
                        titulo: 'Retorno API',
                        mensagem: response.data.message,
                        textoBotao: 'Ok'
                    })
                }
            })
            .catch(function (error) {
                console.error(error)
                setModalCliente(true)
                setLoading(false)
                setAlerta({
                    show: true,
                    titulo: 'ATENÇÃO!',
                    mensagem: error.response.data.message,
                    textoBotao: 'Ok'
                })
            })
    }

    async function updateCliente(values) {
        axios.post(conexao.endereco + '/customers/edit',
            {
                "user": usuario.user_id ? usuario.user_id : localStorage.getItem('userID'),
                "customer": customer.cust_id,
                "name": values.name,
                "email": values.email,
                "phone": values.phone,
                "docnumber": values.docnumber,
                "obs": values.obs,
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                setModalCliente(false)
                if (response.status == 200) {
                    getClientes()
                }
            })
            .catch(function (error) {
                console.error(error)
                setModalCliente(false)
                setLoading(false)
            })
    }

    async function deleteCliente(customer) {
        axios.post(conexao.endereco + '/customers/delete',
            {
                "user": usuario.user_id ? usuario.user_id : localStorage.getItem('userID'),
                "customer": customer
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {
                    setIdSelecionado(0)
                    getClientes()
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    useEffect(() => {
        getClientes()
    }, [])

    return (
        <div>
            <TopBar ativo='clientes' />
            <Container>
                <Header titulo='Clientes'>
                    <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" block type="buttom" onClick={() => {
                        setCustomer({})
                        setEdit(false)
                        setModalCliente(true)
                    }}>
                        Incluir
                    </Button>
                    <Button style={{ backgroundColor: '#026eb6', border: 'none', marginLeft: 15 }} size="sm" block type="buttom" onClick={() => {
                        getClientes()
                    }}>
                        Atualizar
                    </Button>
                </Header>
                <Row style={{ marginTop: 15 }}>
                    <Col>
                        <div style={{ backgroundColor: 'gainsboro', width: '100%', height: 40, borderStartStartRadius: 10, borderStartEndRadius: 10, display: 'flex', justifyContent: 'space-around' }}>
                            <Col lg={3} sm={3} md={3} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Nome
                            </Col>
                            <Col lg={3} sm={3} md={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'flex-start' }}>
                                E-mail
                            </Col>
                            <Col lg={3} sm={3} md={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'flex-start' }}>
                                Telefone
                            </Col>
                            <Col lg={3} sm={3} md={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'flex-start' }}>
                                Documento
                            </Col>
                        </div>
                        {customers.map((item, index) => (

                            <div style={{ backgroundColor: 'white', width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                                <Col lg={3} sm={3} md={3} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15, textTransform: 'capitalize' }}>
                                    {item.cust_name}
                                </Col>
                                <Col lg={3} sm={3} md={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'flex-start' }}>
                                    {item.cust_email}
                                </Col>
                                <Col lg={3} sm={3} md={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'flex-start' }}>
                                    {item.cust_phone ? formatToPhone(item.cust_phone) : ''}
                                </Col>
                                <Col lg={2} sm={2} md={32} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'flex-start' }}>
                                    {item.cust_docnumber ? formatToCPFOrCNPJ(item.cust_docnumber) : ''}
                                </Col>
                                <Col lg={1} sm={1} md={1} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'space-evenly' }}>
                                    <Hint posicao="top" id="edit-item" texto="Editar Cliente">
                                        <div onClick={() => {
                                            setEdit(true)
                                            setCustomer(item)
                                            setModalCliente(true)
                                        }} style={{ cursor: 'pointer' }}>
                                            <MdEdit color="#026eb6" />
                                        </div>
                                    </Hint>
                                    <Hint posicao="top" id="delete-item" texto="Remover Cliente">
                                        <div onClick={() => {
                                            setIdSelecionado(item.cust_id)
                                            setShowConfirmacao(true)
                                        }} style={{ cursor: 'pointer' }}>
                                            <MdDelete color="red" />
                                        </div>
                                    </Hint>
                                </Col>
                            </div>

                        ))}
                        <div style={{ backgroundColor: 'gainsboro', width: '100%', height: 40, borderEndStartRadius: 10, borderEndEndRadius: 10, display: 'flex' }}>
                            <Col lg={5} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                <sub>{customers.length} Cliente(s) Cadastrado(s)</sub>
                            </Col>
                            <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>

                            </Col>
                            <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>

                            </Col>
                            <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>

                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>

            <ModalCliente
                show={modalCliente}
                setShow={setModalCliente}
                onHide={() => setModalCliente(false)}
                centered
                type={edit ? 'edit' : 'insert'}
                insertCliente={insertCliente}
                editCliente={updateCliente}
                values={customer}
            />

            <AlertaMensagem
                show={alerta.show}
                close={() => setAlerta({ ...alerta, show: false })}
                titulo={alerta.titulo}
                mensagem={alerta.mensagem}
                textoBotao={alerta.textoBotao}
            />

            <ModalConfirmacao
                show={showConfirmacao}
                setShow={setShowConfirmacao}
                onHide={() => setShowConfirmacao(false)}
                centered
                titulo="Confirma Exclusão do Cliente?"
                texto="Está Ação é irreversível"
                clickConfirmar={() => {
                    setShowConfirmacao(false)
                    deleteCliente(idSelecionado)
                }}
            />
        </div>
    )
}

export default TelaClientes