import React, { useState, useEffect, useContext } from "react";
import TopBar from "../../components/topbar";
import Header from "../../components/header";
import { Row, Col, Button, Container, Badge } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { GlobalContext } from "../../contexts/global";
import { MdEdit, MdDelete, MdShare } from "react-icons/md";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { ModalMenuProposta, ModalInputSimples } from "../../components/modals";
import copy from "copy-to-clipboard";
import { AlertaMensagem } from "../../components/alerts";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { update } from "js-md5";



function TelaNegociacao() {

    const { conexao, loading, setLoading, alerta, setAlerta, usuario } = useContext(GlobalContext)

    const Navigate = useNavigate()

    const { idproposta } = useParams()

    const [messages, setMessages] = useState([]);
    const [proposal, setProposal] = useState({});

    const [modalMenu, setModalMenu] = useState(false);
    const [modalEmail, setModalEmail] = useState(false);

    const [propostaSelecionada, setPropostaSelecionada] = useState({});

    const formataData = (dataInformada) => {
        const data = new Date(dataInformada)
        console.log(data)
        const dataFormatada = data.toLocaleDateString("pt-BR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
        return dataFormatada
    }



    async function getMessages() {
        axios.post(conexao.endereco + '/proposals/messages',
            {
                "proposal": base64_decode(idproposta),
                "status": "negociação"
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {
                    setProposal(response.data.proposta)
                    setMessages(response.data.mensagens)
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function updateStatusProposta(status) {

        axios.post(conexao.endereco + '/proposals/edit/status',
            {
                "proposal": propostaSelecionada.prop_id,
                "status": status
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                setLoading(false)
                if (response.status == 200) {
                    toast.info('Status atualizado para ' + status)
                    getMessages()
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    useEffect(() => {
        getMessages()
    }, [])

    return (
        <div>
            <TopBar ativo='propostas' />
            <Container>
                <Header titulo={proposal?.prop_title}>
                    <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" block type="buttom" onClick={() => {
                        Navigate('/propostas/edicao/' + idproposta)
                    }}>
                        Editar Proposta
                    </Button>
                    <Button style={{ backgroundColor: '#026eb6', border: 'none', marginLeft: 15 }} size="sm" block type="buttom" onClick={() => {
                        getMessages()
                    }}>
                        Atualizar
                    </Button>
                </Header>


                <Row style={{ marginTop: 15 }}>
                    <Col>
                        <div style={{ backgroundColor: 'gainsboro', width: '100%', height: 40, borderStartStartRadius: 10, borderStartEndRadius: 10, display: 'flex' }}>
                            <Col lg={8} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Mensagem
                            </Col>
                            <Col lg={4} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                Valor da Proposta Negociada
                            </Col>

                        </div>
                        {messages.map((item, index) => (

                            <div
                                className="item-busca-proposta"
                                style={{ borderBottom: '1px dashed gainsboro' }}
                                // onClick={() => { Navigate(`/propostas/edicao/${base64_encode(item.prop_id)}`) }}
                                onClick={() => {
                                    setPropostaSelecionada(item)
                                    setModalMenu(true)
                                }}
                            >
                                <Col lg={8} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', minHeight: 90, paddingLeft: 15, textTransform: 'capitalize', flexDirection: 'column' }}>
                                    <div style={{ whiteSpace: 'pre-line', wordWrap: "break-word", fontSize: 11, paddingBottom: 5, display: 'flex', height: '100%', paddingTop: 5, color: 'grey' }}>
                                        {formataData(item.prom_created)} - {item.prom_type}
                                    </div>
                                    <div style={{ whiteSpace: 'pre-line', wordWrap: "break-word", fontSize: 14, paddingBottom: 5, display: 'flex', height: '100%', alignItems: 'center' }}>
                                        <span>{item.prom_message}</span>
                                    </div>
                                </Col>
                                <Col lg={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 90, paddingLeft: 15, flexDirection: 'column' }}>
                                    <div style={{ textTransform: 'capitalize' }}>
                                        {JSON.parse(item.prom_data)?.valor_proposta ? Number(JSON.parse(item.prom_data).valor_proposta).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : null}
                                    </div>
                                    <div>
                                        {/* <sup>{item.prom_data}</sup> */}
                                    </div>
                                </Col>
                                {/* <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 60, justifyContent: 'center' }}>
                                    {item.prop_final_value ? Number(item.prop_final_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}
                                </Col>
                                <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 60, justifyContent: 'center' }}>
                                    <Badge bg={
                                        item.prop_status == 'rascunho' ? 'info' :
                                            item.prop_status == 'enviada' ? 'primary' :
                                                item.prop_status == 'negociação' ? 'warning' :
                                                    item.prop_status == 'aprovada' ? 'success' : 'danger'
                                    }>
                                        {item.prop_status}
                                    </Badge>
                                </Col> */}
                                {/* <Col lg={1} style={{ display: 'flex', alignItems: 'center', height: 60, justifyContent: 'space-evenly', zIndex: 10 }}>
                                    <Hint posicao="top" id="send-proposal" texto="Enviar Proposta">
                                        <div onClick={() => {
                                            setModalCompartilhar(true)
                                        }} style={{ cursor: 'pointer' }}>
                                            <MdShare color="green" />
                                        </div>
                                    </Hint>
                                    <Hint posicao="top" id="delete-proposal" texto="Excluir Proposta">
                                        <div onClick={() => {
                                            // deleteCliente(item.cust_id)
                                        }} style={{ cursor: 'pointer' }}>
                                            <MdDelete color="red" />
                                        </div>
                                    </Hint>
                                </Col> */}
                            </div>

                        ))}
                        <div style={{ backgroundColor: 'gainsboro', width: '100%', height: 40, borderEndStartRadius: 10, borderEndEndRadius: 10, display: 'flex' }}>
                            <Col lg={5} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                <sub>{messages.length} Mensagen(s) Cadastrada(s)</sub>
                            </Col>
                            <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>

                            </Col>
                            <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>

                            </Col>
                            <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>

                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
            <AlertaMensagem
                show={alerta.show}
                close={() => setAlerta({ ...alerta, show: false })}
                titulo={alerta.titulo}
                mensagem={alerta.mensagem}
                textoBotao={alerta.textoBotao}
            />
            <ModalMenuProposta
                show={modalMenu}
                setShow={setModalMenu}
                onHide={() => setModalMenu(false)}
                centered
                clickEditar={() => {
                    setModalMenu(false)
                    Navigate(`/propostas/edicao/${base64_encode(propostaSelecionada.prop_id)}`)
                }}
                clickNegociacao={() => {
                    setModalMenu(false)
                    toast.success('Negociação')
                }}
                clickEnviarEmail={() => {
                    if (usuario.user_plan == 2) {
                        setModalMenu(false)
                        setModalEmail(true)
                    } else {
                        setAlerta({
                            show: true,
                            titulo: 'Opção Indisponível',
                            mensagem: 'O Envio de Propostas por e-mail está disponível apenas no plano PROFISSIONAL',
                            textoBotao: 'Ok'
                        })
                    }
                    // setModalMenu(false)

                }}
                clickEnviarWhatsApp={() => {
                    if (usuario.user_plan == 2) {
                        setAlerta({
                            show: true,
                            titulo: 'Opção Indisponível no Momento',
                            mensagem: 'O Envio de Propostas por WhatsApp estará disponível em breve no plano PROFISSIONAL',
                            textoBotao: 'Ok'
                        })
                    } else {
                        setAlerta({
                            show: true,
                            titulo: 'Opção Indisponível',
                            mensagem: 'O Envio de Propostas por WhatsApp está disponível apenas no plano PROFISSIONAL',
                            textoBotao: 'Ok'
                        })
                    }
                    // setModalMenu(false)

                }}
                clickLink={() => {
                    setModalMenu(false)
                    const link = `${window.location.host}/cliente/proposta/${base64_encode(propostaSelecionada.prop_id)}`
                    copy(link)
                    updateStatusProposta('enviada')
                    toast.success('Link Copiado!')
                    // setAlerta({
                    //     show: true,
                    //     titulo: 'Link de Acesso a Proposta',
                    //     mensagem: 'Link foi Copiado e Proposta foi Marcada como Enviada!',
                    //     textoBotao: 'Ok'
                    // })
                }}
            />
            <ModalInputSimples
                show={modalEmail}
                setShow={setModalEmail}
                onHide={() => setModalEmail(false)}
                centered
                titulo="Enviar Proposta por E-mail"
                descricao="Envie sua proposta por e-mail"
                botao="Enviar E-mail"
                valor={propostaSelecionada.cust_email}
                tipo="email"
                clickBotao={(email) => {
                    setModalEmail(false)
                    // sendPropostaEmail(propostaSelecionada.prop_id, email)
                }}

            />
            <ToastContainer />
        </div>

    )
}

export default TelaNegociacao