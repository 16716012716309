import React, { useRef } from "react";
import { Col, Row, Modal, Button, Spinner } from "react-bootstrap";
import { Formik, Form } from "formik";
import { ErroField, InputFieldPadrao, InputFieldTextArea, SelectFieldPadrao } from "./fields";
import * as Yup from "yup";
import { formatToCPFOrCNPJ, formatToPhone } from "brazilian-values";
import { MdArchive, MdCancel, MdChat, MdCheck, MdEdit, MdLink, MdSend } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { BiDuplicate } from "react-icons/bi";


export function ModalItemProposta(props) {


    const Schema = Yup.object().shape({
        descricao: Yup.string()
            .required('Campo Obrigatório'),
        quantidade: Yup.string()
            .required('Informe a Quantidade'),
        unitario: Yup.string()
            .required('Informe o Valor Unitário')
    })


    function HandleAddItem(values) {

        props.setItens([...props.itens, values])
        props.setShow(false)

    }

    const somaTotal = (quantidade, unitario) => {

        let soma = 0

        const quant = quantidade.toString().replace(',', '.')
        const valorUnit = unitario.toString().replace(',', '.')

        soma = Number(quant) * Number(valorUnit)

        return soma
    }

    return (
        <Modal
            {...props}
        >
            <Modal.Header closeButton style={{ border: 'none' }} >
                <h5>Item da Proposta</h5>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    onSubmit={(values) => {
                        HandleAddItem({
                            descricao: values.descricao,
                            quantidade: values.quantidade,
                            unitario: Number(values.unitario.toString().replace(',', '.')),
                            total: somaTotal(values.quantidade, values.unitario)
                        })
                    }}
                    validationSchema={Schema}
                    initialValues={props.values}
                    render={({ values, errors, touched, isValid }) => (
                        <>
                            <Form autoComplete="off">
                                <Row>
                                    <Col>
                                        <InputFieldPadrao campo="descricao" texto="Descrição" tipo="text" />
                                        <ErroField campo="descricao" />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col lg={4}>
                                        <InputFieldPadrao campo="quantidade" texto="Quantidade" tipo="number" />
                                        <ErroField campo="quantidade" />
                                    </Col>
                                    <Col lg={4}>
                                        <InputFieldPadrao campo="unitario" texto="Valor Unitário" tipo="text" />
                                        <ErroField campo="unitario" />
                                    </Col>
                                    <Col lg={4}>
                                        <label htmlFor="total" className="form-label">Valor Total</label>
                                        <input id="total" value={values.quantidade && values.unitario ? somaTotal(values.quantidade, values.unitario).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0} type="text" className="form-control" disabled />
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 35 }}>
                                    <Col className="d-grid gap-2">
                                        <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" type="submit">
                                            Adicionar Item
                                        </Button>
                                    </Col>
                                </Row>

                            </Form>

                        </>
                    )}
                />
            </Modal.Body>
        </Modal>
    )

}

export function ModalDesconto(props) {

    const refDesconto = useRef()

    return (
        <Modal  {...props}>

            <Modal.Header closeButton style={{ border: 'none' }} >
                <h5>Informe o Desconto</h5>
            </Modal.Header>
            <Modal.Body>
                <strong>Valor Atual da Proposta: </strong><span>{props.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span><br />
                <Formik
                    onSubmit={(values) => {
                        props.setDesconto(Number(values.desconto.toString().replace(',', '.')))
                        props.setShow(false)
                    }}
                    // validationSchema={Schema}
                    innerRef={refDesconto}
                    initialValues={
                        {
                            desconto: props.desconto
                        }
                    }
                    render={({ values, errors, touched, isValid }) => (
                        <>
                            <Form autoComplete="off">
                                <Row style={{ marginTop: 40 }}>
                                    <Col>
                                        <InputFieldPadrao campo="desconto" texto="Desconto em Valor (R$)" tipo="number" />
                                        <ErroField campo="desconto" />
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 35 }}>
                                    <Col className="d-grid gap-2">
                                        <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" type="submit">
                                            Aplicar Desconto
                                        </Button>
                                    </Col>
                                </Row>

                            </Form>

                        </>
                    )}
                />

            </Modal.Body>

        </Modal>
    )
}

export function ModalConfirmacao(props) {


    return (
        <Modal  {...props}>

            <Modal.Header closeButton style={{ border: 'none' }} >
                <h5>{props.titulo}</h5>
            </Modal.Header>
            <Modal.Body>
                <span>{props.texto}</span>

                <Row style={{ marginTop: 35 }}>
                    <Col className="d-grid gap-2">
                        <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" onClick={props.clickConfirmar} >
                            Confirmar
                        </Button>
                    </Col>
                </Row>

            </Modal.Body>

        </Modal>
    )
}

export function ModalCliente(props) {


    const Schema = Yup.object().shape({
        cust_name: Yup.string()
            .required('Campo Nome é Obrigatório'),
        cust_email: Yup.string()
            .required('Campo E-mail é Obrigatório')
    })

    return (
        <Modal
            {...props}
        >
            <Modal.Header closeButton style={{ border: 'none' }} >
                {props.type == 'insert' &&
                    <h5>Cadastro de Cliente</h5>
                }
                {props.type == 'edit' &&
                    <h5>Edição de Cliente</h5>
                }
            </Modal.Header>
            <Modal.Body>
                <Formik
                    onSubmit={(values) => {

                        if (props.type == 'insert') {
                            props.insertCliente(
                                {
                                    name: values.cust_name,
                                    email: values.cust_email,
                                    phone: values.cust_phone,
                                    docnumber: values.cust_docnumber,
                                    obs: values.cust_obs
                                }
                            )
                        } else {
                            props.editCliente(
                                {
                                    name: values.cust_name,
                                    email: values.cust_email,
                                    phone: values.cust_phone,
                                    docnumber: values.cust_docnumber,
                                    obs: values.cust_obs
                                }
                            )
                        }
                    }}
                    validationSchema={Schema}
                    initialValues={{
                        cust_name: props.values.cust_name,
                        cust_email: props.values.cust_email,
                        cust_docnumber: props.values.cust_docnumber,
                        cust_phone: props.values.cust_phone,
                        cust_obs: props.values.cust_obs
                    }}
                    render={({ values, errors, touched, isValid }) => (
                        <>
                            <Form autoComplete="off">
                                <Row>
                                    <Col>
                                        <InputFieldPadrao campo="cust_name" texto="Nome Completo" tipo="text" />
                                        <ErroField campo="cust_name" />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col>
                                        <InputFieldPadrao campo="cust_email" texto="Endereço de E-mail" tipo="email" />
                                        <ErroField campo="cust_email" />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col lg={6}>
                                        <InputFieldPadrao campo="cust_docnumber" texto="CPF ou CNPJ" tipo="text" />
                                        <ErroField campo="cust_docnumber" />
                                    </Col>
                                    <Col lg={6}>
                                        <InputFieldPadrao campo="cust_phone" texto="Telefone" tipo="text" />
                                        <ErroField campo="cust_phone" />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col>
                                        <InputFieldTextArea campo="cust_obs" texto="Observações" tipo="text" linhas={3} />
                                        <ErroField campo="cust_obs" />
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 35 }}>
                                    <Col className="d-grid gap-2">
                                        {props.type == 'insert' &&
                                            <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" type="submit">
                                                Adicionar Cliente
                                            </Button>
                                        }
                                        {props.type == 'edit' &&
                                            <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" type="submit">
                                                Atualizar Cliente
                                            </Button>
                                        }

                                    </Col>
                                </Row>

                            </Form>

                        </>
                    )}
                />
            </Modal.Body>
        </Modal>
    )

}

export function ModalListaClientes(props) {

    return (
        <Modal
            {...props}
        >
            <Modal.Header closeButton style={{ border: 'none' }} >
                <h5>Selecione o Cliente</h5>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div style={{ backgroundColor: 'gainsboro', width: '100%', height: 40, borderStartStartRadius: 10, borderStartEndRadius: 10, display: 'flex' }}>
                            <Col lg={5} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Nome
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'flex-start' }}>
                                E-mail
                            </Col>
                            <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'flex-start' }}>
                                Telefone
                            </Col>
                            <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'flex-start' }}>
                                Documento
                            </Col>
                        </div>
                        {props.customers.map((item, index) => (

                            <div className="item-busca-cliente" onClick={() => {
                                props.setCustomer(item)
                                props.setShow(false)
                            }}>
                                <Col lg={5} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15, textTransform: 'capitalize' }}>
                                    {item.cust_name}
                                </Col>
                                <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'flex-start' }}>
                                    {item.cust_email}
                                </Col>
                                <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'flex-start' }}>
                                    {item.cust_phone ? formatToPhone(item.cust_phone) : ''}
                                </Col>
                                <Col lg={2} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'flex-start' }}>
                                    {item.cust_docnumber ? formatToCPFOrCNPJ(item.cust_docnumber) : ''}
                                </Col>
                            </div>

                        ))}
                        <div style={{ backgroundColor: 'gainsboro', width: '100%', height: 40, borderEndStartRadius: 10, borderEndEndRadius: 10, display: 'flex' }}>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                <sub>{props.customers.length} Cliente(s) Cadastrado(s)</sub>
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>

                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>

                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>

                            </Col>
                        </div>
                    </Col>
                </Row>

            </Modal.Body>
        </Modal>
    )

}
export function ModalMenuProposta(props) {

    return (

        <Modal  {...props}>
            <Modal.Header closeButton style={{ border: 'none' }} >
                <h5>Opções da Proposta Comercial</h5>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ displa: 'flex', justifyContent: 'space-evenly' }}>
                    <Col className="icone-menu-propostas" onClick={() => {
                        props.clickEditar()
                    }} >
                        <MdEdit size={36} color="#026eb6" />
                        <span>Editar</span>
                    </Col>
                    <Col className="icone-menu-propostas" onClick={() => {
                        props.clickDuplicar()
                    }} >
                        <BiDuplicate size={36} color="#026eb6" />
                        <span>Duplicar</span>
                    </Col>
                    <Col className="icone-menu-propostas" onClick={() => {
                        props.clickLink()
                    }} >
                        <MdLink size={36} color="#026eb6" />
                        <span>Link</span>
                    </Col>
                    <Col className="icone-menu-propostas" onClick={() => {
                        props.clickNegociacao()
                    }} >
                        <MdChat size={36} color="#026eb6" />
                        <span>Negociação</span>
                    </Col>
                    <Col className="icone-menu-propostas" onClick={() => {
                        props.clickEnviarEmail()
                    }} >
                        <MdSend size={36} color="#026eb6" />
                        <span>E-mail</span>
                    </Col>
                    <Col className="icone-menu-propostas" onClick={() => {
                        props.clickEnviarWhatsApp()
                    }} >
                        <FaWhatsapp size={36} color="#026eb6" />
                        <span>WhatsApp</span>
                    </Col>
                </Row>

            </Modal.Body>

        </Modal>
    )
}

export function ModalPlanos(props) {

    return (

        <Modal  {...props}>
            <Modal.Header closeButton style={{ border: 'none' }} >
                <h5>Planos Disponívels</h5>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ paddingBottom: 40 }}>
                    <Col className="card-plano">

                        <div style={{ backgroundColor: 'gainsboro', width: '100%', height: 60, borderStartStartRadius: 10, borderStartEndRadius: 10, display: 'flex', backgroundColor: '#026eb6', color: 'white' }}>
                            <Col lg={12} style={{ display: 'flex', alignItems: 'center', height: 60, paddingLeft: 15 }}>
                                <strong>I N I C I A N T E</strong>
                            </Col>
                        </div>

                        <div className="item-plano" >
                            <Col lg={9} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Propostas Mensais
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                30
                            </Col>
                        </div>
                        <div className="item-plano">
                            <Col lg={9} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Integração E-mail
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                <MdCheck color="green" />
                            </Col>
                        </div>
                        <div className="item-plano">
                            <Col lg={9} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Integração WhatsApp
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                <MdCancel color="red" />
                            </Col>
                        </div>
                        <div className="item-plano">
                            <Col lg={9} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Gerador de Contratos
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                <MdCancel color="red" />
                            </Col>
                        </div>
                        <div className="item-plano">
                            <Col lg={9} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Integração Asaas
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                <MdCancel color="red" />
                            </Col>
                        </div>
                        <div className="item-plano">
                            <Col lg={9} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Inteligência Artificial
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                <MdCancel color="red" />
                            </Col>
                        </div>

                        <div className="item-plano-rodape-mes" onClick={() => { window.open("https://www.asaas.com/c/153532862684", "_blank") }}>
                            <Col lg={8} className="item-plano-rodape-texto" >
                                Assinatura Mensal <sub className="item-plano-rodape-botao-mes">Clique para Assinar</sub>
                            </Col>
                            <Col lg={4} className="item-plano-rodape-preco" >
                                R$ 39,90
                            </Col>
                        </div>
                        <div className="item-plano-rodape-ano" onClick={() => { window.open("https://www.asaas.com/c/418821886702", "_blank") }}>
                            <Col lg={8} className="item-plano-rodape-texto" >
                                Assinatura Anual <sub className="item-plano-rodape-botao-ano">Clique para Assinar</sub>
                            </Col>
                            <Col lg={4} className="item-plano-rodape-preco" >
                                R$ 399,90
                            </Col>
                        </div>
                    </Col>

                    <Col className="card-plano">
                        <div style={{ backgroundColor: 'gainsboro', width: '100%', height: 60, borderStartStartRadius: 10, borderStartEndRadius: 10, display: 'flex', backgroundColor: '#026eb6', color: 'white' }}>
                            <Col lg={12} style={{ display: 'flex', alignItems: 'center', height: 60, paddingLeft: 15 }}>
                                <strong>P R O F I S S I O N A L</strong>
                            </Col>
                        </div>

                        <div className="item-plano">
                            <Col lg={9} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Propostas Ilimitadas
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                <MdCheck color="green" />
                            </Col>
                        </div>
                        <div className="item-plano">
                            <Col lg={9} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Integração E-mail
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                <MdCheck color="green" />
                            </Col>
                        </div>
                        <div className="item-plano">
                            <Col lg={9} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Integração WhatsApp
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                <MdCheck color="green" />
                            </Col>
                        </div>
                        <div className="item-plano">
                            <Col lg={9} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Gerador de Contratos
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                <MdCheck color="green" />
                            </Col>
                        </div>
                        <div className="item-plano">
                            <Col lg={9} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Integração Asaas
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                <MdCheck color="green" />
                            </Col>
                        </div>
                        <div className="item-plano">
                            <Col lg={9} style={{ display: 'flex', alignItems: 'center', height: 40, paddingLeft: 15 }}>
                                Inteligência Artificial
                            </Col>
                            <Col lg={3} style={{ display: 'flex', alignItems: 'center', height: 40, justifyContent: 'center' }}>
                                <MdCheck color="green" />
                            </Col>
                        </div>

                        <div className="item-plano-rodape-mes" onClick={() => { window.open("https://www.asaas.com/c/036792790632", "_blank") }}>
                            <Col lg={8} className="item-plano-rodape-texto" >
                                Assinatura Mensal <sub className="item-plano-rodape-botao-mes">Clique para Assinar</sub>
                            </Col>
                            <Col lg={4} className="item-plano-rodape-preco" >
                                R$ 59,90
                            </Col>
                        </div>
                        <div className="item-plano-rodape-ano" onClick={() => { window.open("https://www.asaas.com/c/174090534265", "_blank") }}>

                            <Col lg={8} className="item-plano-rodape-texto" >
                                Assinatura Anual <sub className="item-plano-rodape-botao-ano">Clique para Assinar</sub>
                            </Col>
                            <Col lg={4} className="item-plano-rodape-preco" >
                                R$ 599,90
                            </Col>
                        </div>

                    </Col>
                </Row>

            </Modal.Body>

        </Modal>
    )
}

export function ModalInputSimples(props) {


    return (
        <Modal  {...props}>

            <Modal.Header closeButton style={{ border: 'none' }} >
                <h5>{props.titulo}</h5>
            </Modal.Header>
            <Modal.Body>

                <Formik
                    onSubmit={(values) => {
                        props.clickBotao(values.valor.toString())
                        props.setShow(false)
                    }}
                    // validationSchema={Schema}

                    initialValues={
                        {
                            valor: props.valor
                        }
                    }
                    render={({ values, errors, touched, isValid }) => (
                        <>
                            <Form autoComplete="off">
                                <Row style={{ marginTop: 20 }}>
                                    <Col>
                                        <InputFieldPadrao campo="valor" texto={props.descricao} tipo={"tipo"} />
                                        <ErroField campo="valor" />
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 35 }}>
                                    <Col className="d-grid gap-2">
                                        <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" type="submit">
                                            {props.botao}
                                        </Button>
                                    </Col>
                                </Row>

                            </Form>

                        </>
                    )}
                />

            </Modal.Body>

        </Modal>
    )
}

export function ModalLoading(props) {


    return (
        <Modal  {...props}>

            <Modal.Body style={{ border: 'none' }} >
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col lg={11} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 18 }}>
                        {props.texto}
                    </Col>
                    <Col lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner animation="border" size="sm" />
                    </Col>

                </Row>

            </Modal.Body>

        </Modal>
    )
}

export function ModalRejeitarProposta(props) {

    const Schema = Yup.object().shape({
        observacoes: Yup.string()
            .required('Descreva o motivo da rejeição')
    })

    return (
        <Modal
            {...props}
        >
            <Modal.Header closeButton style={{ border: 'none' }} >
                <h5>Rejeitar Proposta</h5>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <strong>Você tem certeza que deseja rejeitar esta proposta?</strong>
                    </Col>
                </Row>

                <Formik
                    onSubmit={(values) => {
                        props.click(values)
                        props.setShow(false)
                    }}
                    validationSchema={Schema}
                    initialValues={
                        {
                            observacoes: ""
                        }
                    }
                    render={({ values, errors, touched, isValid }) => (
                        <Form autoComplete="off">
                            <Row style={{ marginTop: 20 }}>
                                <Col>
                                    <InputFieldTextArea campo="observacoes" texto={<sub>Pode explicar o motivo de sua decisão?</sub>} tipo={"text"} linhas={3} />
                                    <ErroField campo="observacoes" />
                                </Col>
                            </Row>

                            <Row style={{ marginTop: 35 }}>
                                <Col className="d-grid gap-2">
                                    <Button style={{ backgroundColor: props.corBotao, border: 'none' }} size="sm" type="submit">
                                        Rejeitar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                />
            </Modal.Body>
        </Modal>
    )
}

export function ModalNegociarProposta(props) {

    const Schema = Yup.object().shape({
        observacoes: Yup.string()
            .required('Informe sua Contra Proposta')
    })

    return (
        <Modal
            {...props}
        >
            <Modal.Header closeButton style={{ border: 'none' }} >
                <h5>Negociar Proposta</h5>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <strong>Informe abaixo sua contra proposta</strong>
                    </Col>
                </Row>

                <Formik
                    onSubmit={(values) => {
                        props.click(values)
                        props.setShow(false)
                    }}
                    validationSchema={Schema}
                    initialValues={
                        {
                            observacoes: ""
                        }
                    }
                    render={({ values, errors, touched, isValid }) => (
                        <Form autoComplete="off">
                            <Row style={{ marginTop: 20 }}>
                                <Col>
                                    <InputFieldTextArea campo="observacoes" tipo={"text"} linhas={3} />
                                    <ErroField campo="observacoes" />
                                </Col>
                            </Row>

                            <Row style={{ marginTop: 35 }}>
                                <Col className="d-grid gap-2">
                                    <Button style={{ backgroundColor: props.corBotao, border: 'none' }} size="sm" type="submit">
                                        Enviar Contra Proposta
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                />
            </Modal.Body>
        </Modal>
    )
}

export function ModalAprovarProposta(props) {
    return (
        <Modal
            {...props}
        >
            <Modal.Header closeButton style={{ border: 'none' }} >
                <h5>Aprovar Proposta</h5>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <strong>Parabéns pela sua escolha!</strong>
                    </Col>
                </Row>

                <Formik
                    onSubmit={(values) => {
                        props.click(values)
                        props.setShow(false)
                    }}
                    // validationSchema={Schema}
                    initialValues={
                        {
                            observacoes: ""
                        }
                    }
                    render={({ values, errors, touched, isValid, setFieldValue }) => (
                        <Form autoComplete="off">

                            {props.tipoProp == 'individual' &&
                                <Row style={{ marginTop: 20 }}>
                                    <Col>

                                        <SelectFieldPadrao campo="item_aprovado" texto="Escolha qual Item você vai aprovar:" onChange={(event) => {
                                            setFieldValue('item_aprovado', event.target.value)
                                        }}>
                                            {props.children}
                                        </SelectFieldPadrao>

                                    </Col>

                                </Row>
                            }

                            <Row style={{ marginTop: 20 }}>
                                <Col>
                                    <InputFieldTextArea campo="observacoes" texto={<sub>Caso tenha alguma observação pode explicar abaixo:</sub>} tipo={"text"} linhas={3} />
                                    <ErroField campo="observacoes" />
                                </Col>
                            </Row>


                            <Row style={{ marginTop: 35 }}>
                                <Col className="d-grid gap-2">
                                    <Button style={{ backgroundColor: props.corBotao, border: 'none' }} size="sm" type="submit">
                                        Aprovar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                />
            </Modal.Body>
        </Modal>
    )
}