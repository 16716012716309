import React, { useEffect, useContext, useState, useRef } from "react";
import TopBar from "../../components/topbar";
import { Alert, Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { GlobalContext } from "../../contexts/global";
import CardIcone from "../../components/card";
import { useNavigate } from "react-router-dom";
import { ModalPlanos } from "../../components/modals";
import { AlertaMensagem } from "../../components/alerts";




function TelaHome() {

    const { conexao, loading, setLoading, alerta, setAlerta, setUsuario, usuario } = useContext(GlobalContext)

    const [userData, setUserData] = useState({});
    const [stats, setStats] = useState({});

    const [modalPlanos, setModalPlanos] = useState(false);

    async function getDataUser() {
        axios.post(conexao.endereco + '/users/info',
            {
                "email": usuario.user_email ? usuario.user_email : localStorage.getItem('email'),
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                if (response.status == 200) {
                    setLoading(false)
                    if (Number(response.data.user.restante < 1 && response.data.user.user_plan == 0)) {
                        setUserData(response.data.user)
                        setUsuario(response.data.user)
                        setAlerta({
                            show: true,
                            titulo: 'Teste Expirado',
                            mensagem: 'Seu períoro de testes foi encerrado, para continuar utilizando a ferramenta será necessário assinar um plano',
                            textoBotao: 'Ok'
                        })

                    } else {
                        setUserData(response.data.user)
                        setUsuario(response.data.user)
                        if (response.data.stats) {
                            setStats(response.data.stats)
                        }

                    }

                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    useEffect(() => {
        getDataUser()
    }, [])


    return (
        <div>
            <TopBar ativo='dashboard' />
            <Container>
                <Row style={{ marginTop: 20 }}>
                    <Col>
                        <h2>Bem vindo ao Proposta Comercial</h2>
                    </Col>
                </Row>
                {usuario.user_plan == 0 && usuario.restante > 0 &&
                    <Row style={{ marginTop: 5 }}>
                        <Col>
                            <Alert variant="warning" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Você está no período de testes de 15 dias, restam ainda {userData.restante} dias para aproveitar a ferramenta!</span>
                                <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" onClick={() => {
                                    setModalPlanos(true)
                                }}>
                                    Ver Planos
                                </Button>
                            </Alert>
                        </Col>
                    </Row>
                }
                {usuario.user_plan == 0 && usuario.restante <= 0 &&
                    <Row style={{ marginTop: 5 }}>
                        <Col>
                            <Alert variant="warning" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Seu período de testes expirou!</span>
                                <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" onClick={() => {
                                    setModalPlanos(true)
                                }}>
                                    Ver Planos
                                </Button>
                            </Alert>
                        </Col>
                    </Row>
                }
                {usuario.user_plan == 1 &&
                    <Row style={{ marginTop: 5 }}>
                        <Col>
                            <Alert variant="success" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Obrigado pela confiança! você está com o plano <strong>INICIANTE</strong> ativo.</span>
                                {/* <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" onClick={() => {
                                    setModalPlanos(true)
                                }}>
                                    Ver Detalhes
                                </Button> */}
                            </Alert>
                        </Col>
                    </Row>
                }
                {usuario.user_plan == 2 &&
                    <Row style={{ marginTop: 5 }}>
                        <Col>
                            <Alert variant="success" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Obrigado pela confiança! você está com o plano <strong>PROFISSIONAL</strong> ativo.</span>
                                {/* <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" onClick={() => {
                                    setModalPlanos(true)
                                }}>
                                    Ver Detalhes
                                </Button> */}
                            </Alert>
                        </Col>
                    </Row>
                }
                <Row style={{ marginTop: 20 }}>
                    <Col>
                        <h3>Resumo das Propostas</h3>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* <CardIcone
                        titulo={`${stats.drafts ? stats.drafts : 0} Rascunho(s)`}
                        icone="MdOutlineDrafts"
                        cor="11caf0"
                    // click={() => { Navigate(`/arquivos/aquisicao_mercadorias/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                    /> */}
                    <CardIcone
                        titulo={`${stats.sent ? stats.sent : 0} Enviada(s)`}
                        icone="MdSend"
                        cor="0f6efd"
                    // click={() => { Navigate(`/arquivos/aquisicao_mercadorias/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                    />
                    <CardIcone
                        titulo={`${stats.approved ? stats.approved : 0} Aprovada(s)`}
                        icone="MdCheckCircle"
                        cor="198754"
                    // click={() => { Navigate(`/arquivos/aquisicao_mercadorias/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                    />
                    <CardIcone
                        titulo={`${stats.disapproved ? stats.disapproved : 0} Reprovada(s)`}
                        icone="MdCancel"
                        cor="dc3546"
                    // click={() => { Navigate(`/arquivos/aquisicao_mercadorias/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                    />
                    <CardIcone
                        titulo={`${stats.negociation ? stats.negociation : 0} Em Negociação`}
                        icone="FaBusinessTime"
                        cor="ffa502"
                    // click={() => { Navigate(`/arquivos/aquisicao_mercadorias/${mesAtual.toLocaleDateString('pt-BR', { month: 'long' })}/${mesAtual.toLocaleDateString('pt-BR', { year: 'numeric' })}`) }}
                    />
                </Row>
            </Container>

            <ModalPlanos
                show={modalPlanos}
                setShow={setModalPlanos}
                onHide={() => setModalPlanos(false)}
                dialogClassName="modal-50"
            // centered
            />

            <AlertaMensagem
                show={alerta.show}
                close={() => setAlerta({ ...alerta, show: false })}
                titulo={alerta.titulo}
                mensagem={alerta.mensagem}
                textoBotao={alerta.textoBotao}
            />
        </div>
    )
}

export default TelaHome;