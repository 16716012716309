import React from "react";
import TopBar from "../../components/topbar";

function TelaSair() {
    return (
        <div>
            <TopBar ativo='sair' />
        </div>
    )
}

export default TelaSair