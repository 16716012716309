import React, { useState, useContext, useRef, useEffect } from "react";
import TopBar from "../../components/topbar";
import { Container, Row, Col, Button } from "react-bootstrap";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { InputFieldPadrao, ErroField, FileFieldPadrao } from "../../components/fields";
import { GlobalContext } from "../../contexts/global";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SketchPicker } from 'react-color';


function TelaConfiguracoes() {

    const Navigate = useNavigate()

    const formConfig = useRef()

    const { conexao, loading, setLoading, usuario, alerta, setAlerta } = useContext(GlobalContext)

    const Schema = Yup.object().shape({
        cust_name: Yup.string()
            .required('Campo Obrigatório')
    })

    const [cfgInicial, setCfgInicial] = useState({
        user_name: '',
        user_business_name: '',
    })

    const [corDestaque, setCorDestaque] = useState('#026EB6')
    const [corHeader, setCorHeader] = useState('#F5F5F5')
    const [corBg, setCorBg] = useState('#DCDCDC')
    const [corFolha, setCorFolha] = useState('#FFFFFF')

    async function updateConfig(values, urlLogo) {

        const colors = {
            corDestaque: corDestaque,
            corHeader: corHeader,
            corBg: corBg,
            corFolha: corFolha
        }

        axios.post(conexao.endereco + '/users/config/update',
            {
                "id": usuario?.user_id,
                "name": values.user_name,
                "business_name": values.user_business_name,
                "url_logo": urlLogo,
                "colors": colors

            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                if (response.status == 200) {
                    toast.success('Configurações Atualizadas com sucesso!')
                    getDataUser()
                }
                else {
                    setAlerta({
                        show: true,
                        titulo: 'Falha ao Enviar a Proposta',
                        mensagem: 'Verifique o e-mail digitado, ou acione nosso suporte',
                        textoBotao: 'Ok'
                    })
                }
            })
            .catch(function (error) {
                setAlerta({
                    show: true,
                    titulo: 'Falha ao Enviar a Proposta',
                    mensagem: error,
                    textoBotao: 'Ok'
                })
            })

    }

    async function RemoverLogomarca(fileName) {

        setLoading(true)

        axios.post(conexao.endereco + '/arquivos/remover',
            {
                arquivo: fileName
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                if (response.status == 200) {
                    updateConfig(formConfig.current.values, null)
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function UploadArquivo(values) {

        setLoading(true)

        const formData = new FormData()
        formData.append('arquivo', values.file)

        axios.post(conexao.endereco + '/arquivos/enviar',
            formData, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                updateConfig(values, response.data.url)
                setLoading(false)
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    async function getDataUser() {
        axios.post(conexao.endereco + '/users/info',
            {
                "email": usuario.user_email,
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                if (response.status == 200) {
                    setLoading(false)
                    formConfig.current.setFieldValue('user_name', response.data.user.user_name)
                    formConfig.current.setFieldValue('user_business_name', response.data.user.user_business_name)
                    formConfig.current.setFieldValue('user_url_logo', response.data.user.user_url_logo)
                    formConfig.current.setFieldValue('nome_arquivo', response.data.user.nome_arquivo)
                    const cores = JSON.parse(response.data.user.user_colors)
                    // alert(cores.corHeader)
                    setCorHeader(cores.corHeader)
                    setCorBg(cores.corBg)
                    setCorDestaque(cores.corDestaque)
                    setCorFolha(cores.corFolha)
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    useEffect(() => {
        getDataUser()
    }, [])

    return (
        <div>
            <TopBar ativo='configuracoes' />
            <Container>
                <Header titulo='Configurações'>
                    <Button style={{ backgroundColor: '#026eb6', border: 'none', marginLeft: 15 }} size="sm" block type="submit" form="formConfig" >
                        Salvar
                    </Button>
                </Header>
                <Formik
                    onSubmit={(values) => {
                        if (values.file) {
                            UploadArquivo(values)
                        } else {
                            updateConfig(values)
                        }
                    }}
                    innerRef={formConfig}
                    // validationSchema={Schema}
                    initialValues={cfgInicial}
                    render={({ values, errors, touched, isValid, setFieldValue }) => (
                        <>
                            <Form autoComplete="off" id="formConfig">
                                <Row>
                                    <Col lg={6}>
                                        <InputFieldPadrao campo="user_name" texto="Seu Nome" tipo="text" />
                                        <ErroField campo="user_name" />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col lg={6}>
                                        <InputFieldPadrao campo="user_business_name" texto="Nome da Empresa" tipo="text" />
                                        <ErroField campo="user_business_name" />
                                    </Col>
                                </Row>
                                {!values.user_url_logo &&
                                    <Row style={{ marginTop: 10 }}>
                                        <Col lg={6}>
                                            <FileFieldPadrao campo="user_url_logo" texto="Logomarca" accept="image/png, image/jpeg" change={(event) => {
                                                setFieldValue("file", event.currentTarget.files[0])
                                            }} />
                                            <ErroField campo="user_url_logo" />
                                        </Col>
                                    </Row>
                                }
                                {values.user_url_logo &&
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
                                        <span>Logomarca Atual</span>
                                        <img src={values.user_url_logo} style={{ maxWidth: '30%', maxHeight: '10%', marginTop: 15, marginBottom: 15 }} />
                                        <sub><a style={{ color: '#026eb6', cursor: 'pointer' }} onClick={() => {
                                            RemoverLogomarca(values.nome_arquivo)
                                        }} >Remover Logomarca </a></sub>

                                    </div>
                                }

                                <Row style={{ marginTop: 40 }}>
                                    <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                        <h4>Cores da Proposta</h4><sub><a style={{ color: '#026eb6', cursor: 'pointer' }} onClick={() => {
                                            setCorDestaque('')
                                            setCorHeader('')
                                            setCorBg('')
                                            setCorFolha('')
                                        }} >Usar Cores Padrões </a></sub>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: 20, marginBottom: 120 }}>
                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <label style={{ marginBottom: 10 }}>Cor de Destaque</label>
                                        <SketchPicker
                                            color={corDestaque}
                                            onChangeComplete={setCorDestaque}
                                        />
                                    </Col>
                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <label style={{ marginBottom: 10 }}>Cor do Cabeçalho</label>
                                        <SketchPicker
                                            color={corHeader}
                                            onChangeComplete={setCorHeader}
                                        />
                                    </Col>
                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <label style={{ marginBottom: 10 }}>Cor do Fundo da Página</label>
                                        <SketchPicker
                                            color={corBg}
                                            onChangeComplete={setCorBg}
                                        />
                                    </Col>
                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <label style={{ marginBottom: 10 }}>Cor do Fundo da Proposta</label>
                                        <SketchPicker
                                            color={corFolha}
                                            onChangeComplete={setCorFolha}
                                        />
                                    </Col>

                                </Row>

                            </Form>

                        </>
                    )}
                />
            </Container>
            <ToastContainer />
        </div>
    )
}

export default TelaConfiguracoes