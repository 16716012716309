import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";


export function AlertaMensagem(props) {

    return (
        <Modal
            show={props.show}
            centered
        >
            <Modal.Header style={{ border: 'none' }} >
                <h5>{props.titulo}</h5>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <div>
                    {props.mensagem}
                </div>
                <div style={{ marginTop: 30 }}>
                    <Button size="sm" style={{ backgroundColor: '#026eb6', border: 'none' }} onClick={() => { props.close() }}>{props.textoBotao}</Button>
                </div>
            </Modal.Body>
            {/* <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                
            </Modal.Footer> */}
        </Modal>
    )
}