import React, { useState, useEffect, useContext } from "react"
import { useNavigate, } from 'react-router-dom';
import { GlobalContext } from "../../contexts/global";
import './style.css';
import md5 from "js-md5";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field } from 'formik'
import { ErroField } from '../../components/fields'
import * as Yup from 'yup';
import axios from 'axios'

function TelaCadastroUsuario() {

    const [textoAcessar, setTextoAcessar] = useState('Acessar')
    const [exibeMsg, setExibeMsg] = useState(false)
    const [tituloMsg, setTituloMsg] = useState(false)
    const [textoMsg, setTextoMsg] = useState(false)

    const { conexao, loading, setLoading, DefineTipoAcesso, SalvarLogin } = useContext(GlobalContext)

    const Navigate = useNavigate()

    useEffect(() => {
        if (loading) {
            setTextoAcessar('Aguarde...')
        } else {
            setTextoAcessar('Cadastrar')
        }
    }, [loading])

    useEffect(() => {
        localStorage.removeItem('Usuário Logado')
        localStorage.removeItem('Token')
        localStorage.removeItem('time_id')
        localStorage.removeItem('time_nome')
    }, [])

    function CadastroNovoUsuario(values) {

        axios.post(conexao.endereco + '/users/create',
            {
                "name": values.name,
                "email": values.email,
                "password": md5(values.password).toUpperCase()
            }, { headers: { token: localStorage.getItem('Token') } })

            .then(function (response) {
                if (response.status == 201) {
                    console.log('Cadastro realizado com sucesso')
                    localStorage.setItem('email', values.email)
                    setLoading(false)
                    Navigate('/')
                }
            })
            .catch(function (error) {
                console.error(error)
                setLoading(false)
            })
    }

    const SchemaCadastro = Yup.object().shape({
        name: Yup.string()
            .required('Campo Obrigatório'),
        email: Yup.string()
            .required('Campo Obrigatório'),
        password: Yup.string()
            .required('Campo Obrigatório')
            .min(3, 'Senha deve ter ao menos 3 digitos'),
    })

    return (
        <div className="main-login">

            {/* <div className="left-login" /> */}

            <div className="right-login">
                <div className="card-login">
                    <div className="logo-login">
                        <h3 className="texto-logo">Crie sua conta</h3>
                        <p style={{ color: 'grey' }}>www.propostacomercial.com.br</p>
                    </div>
                    <Formik
                        onSubmit={CadastroNovoUsuario}
                        validationSchema={SchemaCadastro}
                        initialValues={{
                            name: '',
                            password: '',
                            email: ''
                        }}
                        render={({ values, errors, touched, isValid }) => (
                            <>
                                <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} autoComplete="off">
                                    <div className="textfield">
                                        <label for="name">Nome Completo</label>
                                        <Field name="name" type="text" style={{ textTransform: 'capitalize' }} />
                                        <ErroField campo="name" />
                                    </div>
                                    <div className="textfield">
                                        <label for="email">E-mail</label>
                                        <Field name="email" type="email" style={{ textTransform: 'lowercase' }} />
                                        <ErroField campo="email" />
                                    </div>
                                    <div className="textfield">
                                        <label for="password">Senha</label>
                                        <Field name="password" type="password" />
                                        <ErroField campo="password" />
                                    </div>

                                    <button className="btn-login" type="submit" >{textoAcessar}</button>
                                </Form>

                            </>
                        )}
                    />

                    <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <a href="/" style={{ textDecoration: 'none' }}><strong>Voltar a Tela de Login</strong></a><br />
                        <span>E-mail do Suporte: suporte@outbox.inf.br</span>
                    </div>
                </div>
            </div>

            {/* <TelaLogin exibir={exibeMsg} titulo="Retorno API" mensagem={textoMsg} /> */}

            <Modal show={exibeMsg} centered>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    {tituloMsg}
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {textoMsg}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setExibeMsg(false) }}>OK</Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default TelaCadastroUsuario