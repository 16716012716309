import React from 'react'
import { Col, Card } from 'react-bootstrap'
import { RiUserSearchLine, RiFilePaperLine } from 'react-icons/ri'
import { BsArrowLeftRight, BsPiggyBank, BsFillPlusCircleFill, BsPersonLinesFill, BsBank } from 'react-icons/bs'
import { TbReportMoney } from "react-icons/tb";
import { FaPercentage, FaFileContract, FaHistory, FaUmbrellaBeach, FaBusinessTime } from 'react-icons/fa'
import { GoQuestion } from 'react-icons/go'
import { IoHelpBuoy } from 'react-icons/io5'
import { BiImport, BiExport } from 'react-icons/bi'
import { AiOutlineUnorderedList, AiOutlineFileSearch, AiFillAlert, AiOutlineWarning } from 'react-icons/ai'
import { MdCancel, MdEvent, MdEventAvailable, MdOutlinePendingActions, MdAutoFixHigh, MdOutlinePriorityHigh, MdOutlineDesignServices, MdOutlineLibraryBooks, MdOutlineDrafts, MdSend, MdCheckCircle } from 'react-icons/md'
import { HiOutlineReceiptTax, HiOutlineNewspaper, HiOutlineDocumentText } from 'react-icons/hi'
import { GiHandTruck } from 'react-icons/gi'

function RenderizaIcone(props) {

    switch (props.icone) {
        case 'GoQuestion':
            return (<GoQuestion color={props.cor} size="36" />)
        case 'BiImport':
            return (<BiImport color={props.cor} size="36" />)
        case 'BiExport':
            return (<BiExport color={props.cor} size="36" />)
        case 'AiOutlineUnorderedList':
            return (<AiOutlineUnorderedList color={props.cor} size="36" />)
        case 'MdCancel':
            return (<MdCancel color={props.cor} size="36" />)
        case 'FaFileContract':
            return (<FaFileContract color={props.cor} size="36" />)
        case 'FaPercentage':
            return (<FaPercentage color={props.cor} size="36" />)
        case 'BsArrowLeftRight':
            return (<BsArrowLeftRight color={props.cor} size="36" />)
        case 'AiOutlineFileSearch':
            return (<AiOutlineFileSearch color={props.cor} size="36" />)
        case 'RiUserSearchLine':
            return (<RiUserSearchLine color={props.cor} size="36" />)
        case 'MdEvent':
            return (<MdEvent color={props.cor} size="36" />)
        case 'MdEventAvailable':
            return (<MdEventAvailable color={props.cor} size="36" />)
        case 'HiOutlineReceiptTax':
            return (<HiOutlineReceiptTax color={props.cor} size="36" />)
        case 'FaHistory':
            return (<FaHistory color={props.cor} size="36" />)
        case 'MdOutlinePendingActions':
            return (<MdOutlinePendingActions color={props.cor} size="36" />)
        case 'BsPiggyBank':
            return (<BsPiggyBank color={props.cor} size="36" />)
        case 'IoHelpBuoy':
            return (<IoHelpBuoy color={props.cor} size="36" />)
        case 'BsFillPlusCircleFill':
            return (<BsFillPlusCircleFill color={props.cor} size="36" />)
        case 'AiFillAlert':
            return (<AiFillAlert color={props.cor} size="36" />)
        case 'MdAutoFixHigh':
            return (<MdAutoFixHigh color={props.cor} size="36" />)
        case 'BsPersonLinesFill':
            return (<BsPersonLinesFill color={props.cor} size="36" />)
        case 'MdOutlinePriorityHigh':
            return (<MdOutlinePriorityHigh color={props.cor} size="36" />)
        case 'BsBank':
            return (<BsBank color={props.cor} size="36" />)
        case "TbReportMoney":
            return (<TbReportMoney color={props.cor} size="36" />)
        case "AiOutlineWarning":
            return (<AiOutlineWarning color={props.cor} size="36" />)
        case "FaUmbrellaBeach":
            return (<FaUmbrellaBeach color={props.cor} size="36" />)
        case "GiHandTruck":
            return (<GiHandTruck color={props.cor} size="36" />)
        case "HiOutlineNewspaper":
            return (<HiOutlineNewspaper color={props.cor} size="36" />)
        case "RiFilePaperLine":
            return (<RiFilePaperLine color={props.cor} size="36" />)
        case "MdOutlineDesignServices":
            return (<MdOutlineDesignServices color={props.cor} size="36" />)
        case "MdOutlineLibraryBooks":
            return (<MdOutlineLibraryBooks color={props.cor} size="36" />)
        case "HiOutlineDocumentText":
            return (<HiOutlineDocumentText color={props.cor} size="36" />)
        case "MdOutlineDrafts":
            return (<MdOutlineDrafts color={props.cor} size="36" />)
        case "FaBusinessTime":
            return (<FaBusinessTime color={props.cor} size="36" />)
        case "MdSend":
            return (<MdSend color={props.cor} size="36" />)
        case "MdCheckCircle":
            return (<MdCheckCircle color={props.cor} size="36" />)
        default:
            break
    }

}

{/* <GoQuestion color={props.cor} size="36" /> */ }
export default function CardIcone(props) {

    const cor = props.cor

    return (
        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={props.click}>
            <Card className="card-interno" style={{backgroundColor: cor }}>
                <Card.Body>
                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <RenderizaIcone icone={props.icone} cor={props.cor}/>
                    </Card.Header>
                    <Card.Title style={{ textAlign: 'center', fontSize: 16 }}>{props.titulo}</Card.Title>
                </Card.Body>

            </Card>
        </Col>
    )
}

