import React from "react";
import TopBar from "../../components/topbar";
import Header from "../../components/header";
import { Container, Button } from "react-bootstrap";

function TelaFinanceiro() {
    return (
        <div>
            <TopBar ativo='financeiro' />
            <Container>
                <Header titulo='Financeiro'>
                    <Button style={{ backgroundColor: '#026eb6', border: 'none' }} size="sm" block type="buttom" form="propRef" onClick={() => {
                        alert('Teste')
                    }}>
                        Incluir
                    </Button>
                </Header>
            </Container>
        </div>
    )
}

export default TelaFinanceiro