import { Row, Col, Button, Container } from 'react-bootstrap'

export default function Header(props) {
    return (
        <Container >
            <Row style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5, paddingTop: 20 }}>
                <Col sm={12} lg={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', minHeight: 50, fontSize: 22 }}>
                    {props.titulo}
                </Col>
                <Col sm={12} lg={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {props.children}
                </Col>
                {/* <Row> */}
                    <hr />
                {/* </Row> */}
            </Row>
        </Container>
    )
}