import React, { useState, createContext } from 'react'

export const GlobalContext = createContext({})

function GlobalProvider({ children }) {


    const [loading, setLoading] = useState(false)
    const [textoLoading, setTextoLoading] = useState('')

    const [alerta, setAlerta] = useState({
        show: false,
        titulo: '',
        mensagem: '',
        textoBotao: 'Ok'
    })

    //Dados Login
    const [usuario, setUsuario] = useState({})

    //Dados Solicitações
    const statusProposta =
        ["rascunho", "enviada", "aprovada", "reprovada"]


    const [conexao] = useState({
        endereco: process.env.REACT_APP_ENDERECO_WS,
        autenticacao: localStorage.getItem('Token')
    })

    return (
        <GlobalContext.Provider value={{
            usuario,
            setUsuario,
            conexao,
            loading,
            setLoading,
            alerta,
            setAlerta,
            textoLoading,
            setTextoLoading
        }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider;