import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GlobalProvider from './contexts/global'

//pages
import TelaLogin from './pages/Login';
import TelaCadastroUsuario from './pages/Cadastro';


import HomePage from './pages/Site';
import TelaHome from './pages/Home';
import TelaClientes from './pages/Clientes';
import TelaPropostas from './pages/Propostas';
import TelaEdicaoProposta from './pages/Propostas/edicao';
import TelaNegociacao from './pages/Propostas/negociacao';
import TelaPropostaCliente from './pages/Propostas/cliente';
import TelaFinanceiro from './pages/Financeiro';
import TelaConfiguracoes from './pages/Configuracoes';
import TelaSair from './pages/Sair';

function App() {
  return (
    <GlobalProvider>

      <BrowserRouter>

        <Routes>
          <Route exact path="/" element={<TelaLogin />} />
          <Route exact path="/dashboard" element={<TelaHome />} />
          <Route exact path="/clientes" element={<TelaClientes />} />
          <Route exact path="/propostas" element={<TelaPropostas />} />
          <Route exact path="/propostas/nova" element={<TelaEdicaoProposta />} />
          <Route exact path="/propostas/negociacao/:idproposta" element={<TelaNegociacao />} />
          <Route exact path="/propostas/edicao/:idproposta" element={<TelaEdicaoProposta />} />
          <Route exact path="/cliente/proposta/:idproposta" element={<TelaPropostaCliente />} />
          <Route exact path="/financeiro" element={<TelaFinanceiro />} />
          <Route exact path="/configuracoes" element={<TelaConfiguracoes />} />
          <Route exact path="/sair" element={<TelaSair />} />

          <Route exact path="/cadastro" element={<TelaCadastroUsuario />} />
        </Routes>

      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
